1
// rails defaults --------
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import './javascript/channels'
import axios from 'axios'

import './javascript/drift-photo-module.umd.min'
import './javascript/localPackages/trianglify.bundle'

import Toast from 'bootstrap/js/src/toast'
Object.defineProperty(window, 'Toast', {
  value: Toast,
  writable: false
})

Object.defineProperty(window, 'getShowcaseThumbnail', {
  value: async () => {
    const targets = document.getElementsByClassName('showcases-card')
    if (targets.length === 0) {
      return
    }

    const csrfHeader = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : ''
    }

    for (let target of targets) {
      const res = await axios.get(target.dataset.getShowcaseThumbnailPath, { headers: csrfHeader })
      if (res?.data?.thumbnail) {
        target.querySelector('.thumbnail-and-operations').style.backgroundImage = `url(${res.data.thumbnail})`
      }
    }
  },
  writable: false
})

Rails.start() // FIXME: yarn vite buildする環境だと二重読み込みエラーが起きるが、この一行が無いとyarn dev環境では@rails/ujsが死ぬ…
Turbolinks.start()
ActiveStorage.start()

import './javascript/shared'
import './javascript/showcase'
