import DriftPhotoModule from '~/entrypoints/javascript/drift-photo-module.es.min'

document.addEventListener('turbolinks:load', () => {
  const showcaseIframe = document.getElementById('showcase')
  if (!showcaseIframe) { // NOTE: model_sidやversion指定が間違っている時にiframe#showcaseが存在しない
    return
  }

  const data = showcaseIframe.dataset
  const playButton = document.getElementById('cover-page-play-button')
  if (playButton) {
    playButton.addEventListener('click', async () => {
      const coverPageWrapper = document.getElementById('cover-page-wrapper')
      coverPageWrapper.classList.add('d-none')

      if (playButton.dataset.next === 'guestbook') {
        document.getElementById('guestbook-form-wrapper').classList.remove('d-none')
      } else {
        showcaseIframe.parentElement.classList.remove('d-none')
        const DPM = new DriftPhotoModule(data.sid, data)
        await DPM.load()
      }
    })
  }

  // Shared Experience
  if (data.room) {
    function parse(event) {
      try {
        return JSON.parse(event.data)
      } catch (error) {
        return null
      }
    }

    // TODO: 最終的にハーフボディが使用出来ない…
    // const subdomain = 'demo'
    const subdomain = 'driftx'
    const avatarInput = document.getElementById('avatar-url')
    const frame = document.getElementById('avatar')
    frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi&bodyType=halfbody`

    window.addEventListener('message', subscribe)
    document.addEventListener('message', subscribe)

    function subscribe(event) {
      const json = parse(event)

      if (json?.source !== 'readyplayerme') {
        return
      }

      if (json.eventName === 'v1.frame.ready') {
        frame.contentWindow.postMessage(JSON.stringify({ target: 'readyplayerme', type: 'subscribe', eventName: 'v1.**' }), '*')
      }

      if (json.eventName === 'v1.avatar.exported') {
        avatarInput.value = json.data.url
        check()
      }
    }

    const entryButton = document.getElementById('shared-experience-entry-button')
    const nameInput = document.getElementById('shared-experience-entry-name')
    const useDefaultAvatar = document.getElementById('use-default-avatar')

    const check = () => {
      if (nameInput.value.length === 0 || (avatarInput.value.length === 0 && !useDefaultAvatar.checked)) {
        entryButton.disabled = true
      } else {
        entryButton.disabled = false
      }

      if (nameInput.value.length === 0) {
        document.getElementById('name-input-error').style.display = 'block'
      } else {
        document.getElementById('name-input-error').style.display = 'none'
      }
      if (avatarInput.value.length === 0 && !useDefaultAvatar.checked) {
        document.getElementById('avatar-input-error').style.display = 'block'
      } else {
        document.getElementById('avatar-input-error').style.display = 'none'
      }
    }
    nameInput.addEventListener('input', check)
    useDefaultAvatar.addEventListener('input', check)
    check()

    entryButton.addEventListener('click', async () => {
      showcaseIframe.parentElement.classList.remove('d-none')
      document.getElementById('shared-experience-entrance').classList.add('d-none')
      const DPM = new DriftPhotoModule(data.sid, { ...data, ...{
        sharedExperience: {
          room: data.room,
          userId: data.userId,
          isHost: data.host === 'true',
          name: nameInput.value,
          num: data.num,
          avatarUrl: `${useDefaultAvatar.checked ? 'https://models.readyplayer.me/64115e23e8584648bb29bcbd.glb' : avatarInput.value}?quality=low&useHands=false`
          // NOTE: https://docs.readyplayer.me/ready-player-me/avatar-api/3d-avatars morphのオプションがあるから口パクとかも出来そう
        }
      } } )
      await DPM.load()
    })
  }
})
